import { Paths } from '../app/settings/models/enviroment-consts/paths';

export const environment = {
  production: true,
  SECURITY: 'commons.pprod-bam.com/security/v1/auth', //legacy
  SECURITY_OIDC: 'commons.pprod-bam.com/security/v1/auth', //security
  SITEKEYV2: '6LePU-YZAAAAAHOb7wLuXcPyHn91bblRgop19d0_',
  CONTENT_MANAGEMENT: `d2ij6h2sal.execute-api.us-east-1.amazonaws.com${Paths.CONTENT_MANAGEMENT}`,
  assets: 'https://assets.pprod-bam.com/contenido_compartido/',
};
